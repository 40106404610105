import dayjs from "dayjs";
import { Link, } from "react-router-dom";
import { useEffect, useState } from "react";
import relativeTime from "dayjs/plugin/relativeTime";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";

import RightDrawer from "../RightDrawer";
import flexbox from "../../Style/flexbox";
import { baseTextStyle } from "../../Style/base";
import { useFetch } from "../../context/NetworkAdapter";
import { FONT_FAMILY, FONT_WEIGHT } from "../../constants";
import { Blue, Gray, Orange, Shades } from "../../Style/colors";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as TappLogo } from "../../assets/icons/tapp-logo.svg";
import {
  INotification,
  INotificationData,
  INotificationResponse,
  NotificationItemProps,
} from "./NotificationPanel.types";
import { useLMS } from "../../context/LMSContext";

const limit: number = 20;
dayjs.extend(relativeTime);

function NotificationItem(props: NotificationItemProps): JSX.Element {
  const { data } = props;

  const currentPath = window.location.href;

  const { module, task } = useLMS();

  const refetchModuleDetails = () => {
    if (currentPath === data.link) {
      task.selectTask(data?.program?.current_task); // on same url but different task selected
      module.fetchDetails(data?.program?.current_module, data?.student?.id, true);
    }
    props?.onClose?.();
  }

  return (
    <Link
      to={data.link}
      state={{
        ...(data?.student
          ? { student: { ...data?.student, _id: data?.student?.id }, module_id: (data?.unlocked_module_id ? data?.unlocked_module_id : data?.program?.current_module) }
          : {}),
        program: data?.program,
        ...(data?.unlocked_module_id ? {} : { taskId: data?.program?.current_task })
      }}
      style={{ textDecoration: "none", width: "100%" }}
      // onClick={(): void => props?.onClose?.()}
      onClick={() => refetchModuleDetails()}
    >
      <Box
        sx={{
          width: "100%",
          ...flexbox("row", "flex-start", "flex-start", "12px", "16px"),
          borderBottom: `1px solid ${Gray["100"]}`,
          ":hover": { background: Gray["50"] },
          cursor: "pointer",
          background: data?.is_read ? Gray["50"] : Shades["0"],
        }}
      >
        <Box>
          {data?.type === "user" ? (
            <Box
              sx={{
                width: 48,
                height: 48,
                borderRadius: 1000,
                bgcolor: Orange["100"],
                display: "grid",
                placeItems: "center",
              }}
            >
              <Typography
                sx={{
                  ...baseTextStyle(
                    FONT_FAMILY.HEADING,
                    FONT_WEIGHT.EXTRA_BOLD,
                    "16px",
                    Orange["800"]
                  ),
                }}
              >
                {data?.user_name?.substring(0, 1)}
              </Typography>
            </Box>
          ) : (
            <TappLogo />
          )}
        </Box>

        <Box
          sx={{
            ...flexbox("column", "flex-start", "flex-start", "8px"),
            width: "100%",
          }}
        >
          <Tooltip title={data?.title} placement="bottom" arrow>
            <Typography
              sx={{
                ...baseTextStyle(
                  FONT_FAMILY.BODY,
                  FONT_WEIGHT.NORMAL,
                  "14px",
                  Gray["900"]
                ),
              }}
            >
              {data?.title?.length > 40 ? data?.title.substring(0, 40) : data?.title}
            </Typography>
          </Tooltip>
          <Typography
            sx={{
              ...baseTextStyle(
                FONT_FAMILY.BODY,
                FONT_WEIGHT.NORMAL,
                "12px",
                Gray["1200"]
              ),
            }}
          >
            {dayjs(data?.created_at)?.fromNow()}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
}

function NotificationLoader({ key }: any): JSX.Element {
  return (
    <Box
      key={key}
      sx={{
        ...flexbox("row", "flex-start", "flex-start", "12px", "16px"),
        width: "100%",
      }}
    >
      <Skeleton
        sx={{ fontSize: "1rem", minWidth: 48, height: 48 }}
        variant="circular"
      />

      <Box
        sx={{
          ...flexbox("column", "flex-start", "flex-start", "8px"),
          width: "100%",
        }}
      >
        <Skeleton sx={{ fontSize: "1rem", width: "100%" }} variant="rounded" />
        <Skeleton sx={{ fontSize: "1rem", width: 48 }} variant="rounded" />
      </Box>
    </Box>
  );
}

export default function NotificationPanel(props: any): JSX.Element {
  const { data, onClosePanel, open } = props;

  const fetch = useFetch();
  const [notifs, setNotifs] = useState<INotificationData>({
    notifications: [],
    total: 0,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    if (open) {
      (async (): Promise<void> => {
        setLoader(true);
        let res: INotificationResponse = await fetch(
          `notification-list?offset=${offset}&limit=${limit}`
        );
        if (res?.status) {
          setNotifs((prev: any) => {
            return {
              notifications:
                offset === 0
                  ? [...res.data.notifications]
                  : [...prev.notifications, ...res.data.notifications],
              total: res.data.total,
            };
          });
          setLoader(false);
        } else {
          setLoader(false);
        }
      })();
    }
  }, [open, offset, data?.get]);

  function updateNotification() {
    return setNotifs;
  }

  useEffect(() => {
    // @ts-ignore
    window.updateNotification = updateNotification;
  }, []);

  const handleClosePanel = () => {
    setOffset(0);
    onClosePanel();
  };

  return (
    <RightDrawer
      {...props}
      sx={{
        top: "61px",
        "> .MuiBackdrop-root": { top: "61px", background: Gray["60"] },
        "> .MuiPaper-root": { boxShadow: "none", top: "61px" },
      }}
    >
      <Box sx={{ width: 416, mb: "82px" }}>
        <Box
          sx={{
            ...flexbox("row", "space-between", "center", null, "16px 24px"),
            borderBottom: `1px solid ${Gray["100"]}`,
          }}
        >
          <Typography
            sx={baseTextStyle(
              FONT_FAMILY.HEADING,
              FONT_WEIGHT.EXTRA_BOLD,
              20,
              Shades["100"]
            )}
          >
            Notification
          </Typography>
          <Box
            sx={{
              cursor: "pointer",
              width: 32,
              minWidth: 32,
              height: 32,
              minHeight: 32,
            }}
            onClick={() => handleClosePanel()}
          >
            <CloseIcon />
          </Box>
        </Box>

        <Box
          sx={{ ...flexbox("column", "center", "center", "0", "16px 24px") }}
        >
          {loader &&
            offset === 0 &&
            notifs.notifications.length === 0 &&
            Array(5)
              .fill(0)
              .map((_i: number, j: number) =>
                parseInt(`${Math.random() * 1000 + j + 1}`)
              )
              .map((item: any) => <NotificationLoader key={item} />)}

          {notifs.notifications?.map(
            (item: INotification): JSX.Element => (
              <NotificationItem
                key={item.id}
                data={item}
                onClose={() => handleClosePanel()}
              />
            )
          )}

          {!loader && notifs.total === 0 ? (
            <>
              <Box sx={{ width: "100%", p: 4 }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    ...baseTextStyle(
                      FONT_FAMILY.BODY,
                      FONT_WEIGHT.MEDIUM,
                      "18px",
                      Gray["400"]
                    ),
                  }}
                >
                  No notifications yet
                </Typography>
              </Box>
            </>
          ) : undefined}

          {loader &&
            offset > 0 &&
            Array(5)
              .fill(0)
              .map((_i: number, j: number) =>
                parseInt(`${Math.random() * 1000 + j + 1}`)
              )
              .map((item: any) => <NotificationLoader key={item} />)}

          {notifs.total === notifs?.notifications?.length ? null : (
            <Typography
              sx={{
                ...baseTextStyle(
                  FONT_FAMILY.BODY,
                  FONT_WEIGHT.NORMAL,
                  "16px",
                  Blue["500"]
                ),
                mt: "12px",
                cursor: "pointer",
              }}
              onClick={(): void => {
                setOffset((prev: number) => prev + 20);
              }}
            >
              Load More
            </Typography>
          )}
        </Box>
      </Box>
    </RightDrawer>
  );
}
