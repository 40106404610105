import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Buttons from "../../components/reusables/Buttons";
import styled from "styled-components";

const AlertTypo = styled.div`
  text-align: center;
  font-family: var(--fontFamiljen);
  font-size: 14px;
  font-weight: var(--font400);
  color: var(--inerTextColor);
`;

export default function AlertDialog(props: any) {
  const {
    confirmBox,
    setConfirmBox,
    onClick,
    title,
    disableData,
    disableMentorData,
    message,
    submitting,
    hideBackDrop,
    nameBtn1,
    nameBtn2,
    nameBtn3,
    imgSrc,
  } = props;

  const handleClose = () => {
    setConfirmBox(false);
  };

  return (
    <Dialog
      open={confirmBox}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      hideBackdrop={hideBackDrop ? true : false}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          textAlign: "center",
          fontFamily: "var(--fontFamiljen)",
          fontWeight: "var(--font500)",
          fontSize: "1.125rem",
          color: "var(--black)",
          padding: "0rem 1rem 0.625rem 1rem!important",
        }}
      >
        <Box
          sx={{
            width: "3.5rem",
            height: "3.5rem",
            borderRadius: "16px",
            margin: "0 auto",
            marginBottom: "0.625rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "var(--optionBtnBg)",
          }}
        >
          <img src={imgSrc} alt="icon" width={32} height={32} />
        </Box>
        {title}
      </DialogTitle>

      <DialogContent sx={{ padding: "0rem 1rem 0.625rem 1rem!important" }}>
        <AlertTypo id="alert-dialog-description">{message}</AlertTypo>
      </DialogContent>

      <DialogActions
        sx={{ justifyContent: "center", gap: "1rem", display: "flex" }}
      >
        {nameBtn3 ? (
          <Buttons
            type="submit"
            name={nameBtn3}
            variant="contained"
            color="primary"
            size="small"
            fullWidth={false}
            sx={{
              padding: "0.563rem 1rem",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            className="BtnHover"
            onClick={onClick}
          />
        ) : (
          <>
            <Buttons
              type="submit"
              name={nameBtn1}
              variant="contained"
              color="inherit"
              size="small"
              fullWidth={false}
              sx={{
                padding: "0.563rem 1rem",
                borderRadius: "8px",
                textTransform: "capitalize",
              }}
              onClick={handleClose}
            />
            <Buttons
              type="submit"
              disabled={
                submitting || disableData > 0 || disableMentorData > 0
              }
              name={nameBtn2}
              variant="contained"
              color="error"
              size="small"
              fullWidth={false}
              sx={{
                padding: "0.5rem 1rem",
                borderRadius: "8px",
                textTransform: "capitalize",
              }}
              className="BtnHover"
              onClick={onClick}
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
